import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Work = ({ location }) => (
  <Layout location={location} title="Work" subTitle="施工実績">
    <SEO title="施工実績" />
    <section>
      <ul>
        <li>
          <figure>
            <img src="" alt="" />
            <figcaption>
              <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
              <p>2020.10.26 小牧市</p>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <img src="" alt="" />
            <figcaption>
              <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
              <p>2020.10.26 小牧市</p>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <img src="" alt="" />
            <figcaption>
              <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
              <p>2020.10.26 小牧市</p>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <img src="" alt="" />
            <figcaption>
              <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
              <p>2020.10.26 小牧市</p>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <img src="" alt="" />
            <figcaption>
              <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
              <p>2020.10.26 小牧市</p>
            </figcaption>
          </figure>
        </li>
        <li>
          <figure>
            <img src="" alt="" />
            <figcaption>
              <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
              <p>2020.10.26 小牧市</p>
            </figcaption>
          </figure>
        </li>
      </ul>
    </section>
  </Layout>
)

export default Work
